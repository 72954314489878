.Background {
    background-color: var(--bg-color);
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Container {
    padding: 3vmin;
    margin: 4vmin;
    font-size: var(--small-font-size);
    color: var(--std-text-color);
    background-color: var(--container-color);
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);

    display: flex;
    flex-direction: column;
    gap: 1em;
}

.FormContainer {
    font-size: var(--small-font-size);
    min-width: 400px;
    display: flex;
    flex-direction: column;
}

a {
    text-decoration: none;
    color: var(--main-color);
}

.button {
    border: none;
    border-radius: 1vmin;
    color: white;
    background-color: var(--main-color);
    font-size: var(--small-font-size);
    padding: 1vmin;
    cursor: pointer;
}