.Container {
    background-color: var(--container-color);
    margin-top: 1vmin;
    padding: 1vmin;
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
}

.Text {
    text-align: left;
    white-space: pre-wrap;
    font-family: Arial, serif;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
}

.TextTitle {
    padding-top: 20px;
    padding-bottom: 22px;
    font-size: 26px;
    font-weight: 700;
    color: black;
}

.LastUpdated {
    font-size: 14px;
    font-weight: 700;
}