.Title {
    display: flex;
    align-items: flex-start;
    width: 25%;
    gap: 1vmin
}

.TitleText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
}

.Input {
    height: 1em;
    font-size: var(--smaller-font-size);
    padding: 0.5em;

    border: none;
    border-radius: 1vmin;
    background-color: white;
    color: var(--std-text-color);
    overscroll-behavior: contain;
}

.Input.FontSize {
    margin-left: 1vmin;
}

.Input.XYPosition {
    max-width: 3em;
    text-align: center;
    transition: 0.05s ease;
}

.FontSize[type="number"]::-webkit-inner-spin-button,
.FontSize[type="number"]::-webkit-outer-spin-button,
.XYPosition[type="number"]::-webkit-inner-spin-button,
.XYPosition[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.XYPosition[type="number"],
.FontSize[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.Input:focus{
    outline: none;
    background-color: white;
}

.TitleToolbarContainer {
    margin-top: 2vmin;
    color: var(--std-text-color);
    font-size: var(--smaller-font-size);
}

.TitleToolbarFirstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TitleToolbarSecondRow {
    font-size: 0;
    margin-top: 0;
    opacity: 0;
    transition: 0.3s ease;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vmin;
}

.TitleToolbarSecondRow.open {
    opacity: 1;
    font-size: 1em;
    margin-top: 1vmin;
}

.ColorSelectorContainer {
    display: flex;
    align-items: center;
    gap: 1vmin;
    margin-left: 1vmin;
}

.ColorSelector {
    height: 1.5em;
    width: 1.5em;

    border-radius: 1vmin;
    border: 0.25vmin solid lightgray;
    cursor: pointer;
}

.FontSelector {
    margin-left: 1vmin;
    padding-left: 0.8vmin;
    width: 25%;
    height: 2em;
    font-size: 1em;

    border: none;
    border-radius: 1vmin;
    background-color: white;
    color: var(--std-text-color);
}

.FontSelector:focus{
    outline: none;
}

.XYBtnSetting {
    display: flex;
    align-items: center;
}

.ImgBtnSetting {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 0.75em;
    width: 0.75em;
    margin-left: 0.5vmin;
    margin-right: 0.5vmin;
    padding: 0;

    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s ease;
}

.ImgSetting {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.OpenXYCoordsBtn {
    min-width: 7%;
    font-size: var(--smaller-font-size);
    height: 2em;
    margin-left: 1vmin;

    border: 1px solid rgba(0, 0, 0, 6%);
    border-radius: 0.75vmin;
    background-color: white;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.OpenXYCoordsBtn:hover {
    filter: brightness(95%);
}

.OpenXYCoordsImg {
    width: 1.0em;
    height: 1.0em;
    object-fit: contain;
}

.Separator {
    margin-left: 1vmin;
    border-left: 1px solid rgba(0, 0, 0, 25%);
    height: 2.5vmin;
    box-sizing: border-box;
}

.DropTitleBtn {
    min-width: 7%;
    font-size: var(--smaller-font-size);
    height: 2em;
    margin-left: 1vmin;

    border: 1px solid rgba(255, 255, 255, 75%);
    border-radius: 0.75vmin;
    background-color: var(--main-color);
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.DropTitleBtn:hover {
    filter: brightness(95%);
}

.DropTitleImg {
    width: 1.0em;
    height: 1.0em;
    object-fit: contain;
}

.TextAreaPopUp {
    background-color: rgba(0,0,0,10%);
}

.TextArea {
    background-color: rgba(0,0,0,5%);
    border: none;
}

.TextArea:hover {
    /* box-shadow: 1px 0 1px 0 rgba(0,0,0,50%), 0 -1px 1px 0 rgba(0,0,0,50%); */
    background-color: rgba(0,0,0,10%);
}

.TextArea:focus {
    outline: none;
}

.TextArea::placeholder {
    color: var(--meme-title-color);
}

.DragItem {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
}

.DragItemColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.DragItemDot {
    background-color: var(--std-text-color);
    border-radius: 50%;
}
