.Container {
    position: relative;
    background-color: var(--container-color);
    margin-top: 1vmin;
    padding: 4vmin 4vmin 4vmin 4vmin;
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
    font-size: var(--small-font-size);
    color: var(--std-text-color);
}

.FormContainer {
    padding-top: 2vmin;
    font-size: var(--small-font-size);
    display: flex;
    flex-direction: column;
}

.button {
    border: none;
    border-radius: 1vmin;
    color: white;
    background-color: var(--main-color);
    font-size: var(--small-font-size);
    padding: 1vmin;
    cursor: pointer;
}