/*.MemePanelBtnContainer {*/
/*    position: relative;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    gap: 0.25em;*/
/*}*/

.MemePanelBtn {
    height: 1em;
    width: calc(100% - 0.5em);
    cursor: pointer;

    padding: 0.25em;
    border-radius: 0.5vmin;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5em;
}

.MemePanelBtn:hover {
    background-color: rgba(0,0,0,3%);
}

.MemePanelBtnImg {
    height: 1em;
}

.MemePanelBtnTitle {
    font-size: var(--small-font-size);
    white-space: nowrap;
}

.Watermark {
    position: absolute;
    /*top: 98%;*/
    /*left: 98%;*/
    /*transform: translate(-98%, -98%);*/

    /*white-space: pre;*/
    /*font-weight: 500;*/
    /*color: rgba(255, 255, 255);*/
    /*-webkit-text-stroke-width: 1px;*/
    /*-webkit-text-stroke-color: rgba(0, 0, 0);*/
    opacity: 0.50;
}


.ReportImgContainer {
    position: relative;
    text-align: right;
}

.ReportImg {
    height: var(--medium-font-size);
    width: var(--medium-font-size);
    padding: 0.5vmin;
    /*border-radius: 50%;*/
}

.ModalBG {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.ModalBG.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalContainer {
    /*padding-bottom: 1vmin;*/
    width: 600px;
    max-width: 95%;
    font-size: var(--medium-font-size);
    border-radius: 1vmin;
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
}

.ModalTitle {
    padding: 2vmin;
    color: var(--std-text-color);
    font-weight: 500;
    text-align: center;
}

.ModalButton {
    padding: 2vmin 2vmin 2vmin 2vmin;
    width: 100%;
    font-size: var(--small-font-size);

    background-color: transparent;
    border: none;
    color: var(--std-text-color);
    cursor: pointer;
}

.ModalButton:hover {
    background-color: rgba(0, 0, 0, 5%);
}

.ReportOption {
    padding: 2vmin 2vmin 2vmin 2vmin;
    display: flex;
    align-items: center;
    gap: 1vmin;
    color: var(--std-text-color);
}

.ReportOption.clickable {
    cursor: pointer;
}

.ReportOption.clickable:hover {
    background-color: rgba(0,0,0,10%);
}

.ReportOptionImg {
    width: 1.1em;
    height: 1.1em;
    object-fit: contain;
}

.AdditionallyBtnPopUpContainer {
    position: absolute;
    /*top: -1vmin;*/
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5em;
    gap: 0.5em;

    background-color: var(--container-color);
    border-radius: 0.5em;
    box-shadow: var(--container-shadow);
    filter: brightness(0.985);
    transition: 0.07s;

    z-index: 10;
}

.AdditionallyBtnPopUpContainer.closed {
    height: 0;
    padding: 0;
    overflow: hidden;
}

.CloseBtn {
    position: absolute;
    right: 0.85vmin;
    top: 0.85vmin;
    padding: 0.25em;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0.3em;
    /*min-width: 8em;*/
    font-size: var(--small-font-size);
    /*background-color: var(--main-color);*/
    /*background-color: var(--std-text-color);*/
    background-color: white;
    box-shadow: var(--container-shadow);
    /*box-shadow: 0 0px 2px rgba(0,0,0,50%);*/
    /*color: white;*/
    color: var(--std-text-color);
    font-weight: 500;
    cursor: pointer;
    opacity: 100%;
}

.CloseBtn:hover {
    filter: brightness(90%);
}

.CloseImg {
    height: 0.65em;
    object-fit: contain;
}
