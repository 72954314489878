.Container {
    background-color: var(--container-color);
    margin-top: 1vmin;
    padding-left: 2vmin;
    padding-right: 2vmin;
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
}

.AccountBtn {
    padding: 0.5em;
    font-size: var(--smaller-font-size);
    font-weight: 500;
    border: 2px solid;
    border-radius: 1vmin;
    cursor: pointer;
}

.AccountBtn:hover {
    background-color: rgba(0,0,0,5%);
}

.AccountBtn.delete {
    border-color: var(--red-color);
    color: var(--red-color);
}

.AccountBtn.restore {
    border-color: var(--green-color);
    color: var(--green-color);
}

.UpdateBtnContainer {
    font-size: var(--smaller-font-size);
    margin-top: 0.5vmin;
}

.UpdateDisabledBtn {
    padding-left: 0.5em;
    white-space: pre-line;
}

.SendConfirmationEmailBtn {
    padding: 0.5em;
    font-size: var(--smaller-font-size);
    color: white;
    background-color: var(--main-color);
    border: none;
    /*border: 2px solid darkgray;*/
    border-radius: 1vmin;
    cursor: pointer;
}

.SendConfirmationEmailBtn:hover {
    filter: brightness(90%);
}

.SettingsModalContent.accountDeletionSubmit, .SettingsModalContent.amnestySubmit {
    max-width: 400px;
}

.AccountDeletionSubmitTitle, .AmnestySubmitTitle {
    font-size: var(--small-font-size);
    font-weight: 500;
    margin-top: 2vmin;
}

.AccountDeletionSubmitDesc, .AmnestySubmitDesc {
    font-size: var(--smaller-font-size);
    margin-top: 2vmin;
    text-align: left;
}

.AccountDeletionSubmitBtn {
    font-size: var(--smaller-font-size);
    font-weight: 500;
    padding: 0.5em;
    margin-top: 2vmin;
    margin-bottom: 2vmin;
    border-color: var(--red-color);
    color: var(--red-color);
    border: 2px solid;
    border-radius: 1vmin;
    text-align: center;
    cursor: pointer;
}

.AmnestySubmitBtn {
    font-size: var(--smaller-font-size);
    font-weight: 500;
    padding: 0.5em;
    margin-top: 2vmin;
    margin-bottom: 2vmin;
    border-color: var(--std-text-color);
    color: var(--std-text-color);
    border: 2px solid;
    border-radius: 1vmin;
    text-align: center;
    cursor: pointer;
}

.AccountDeletionSubmitBtn:hover, .AmnestySubmitBtn:hover {
    background-color: rgba(0,0,0,5%);
}

.Greetings {
    margin-bottom: 1vmin;
    font-weight: 500;
    font-size: var(--medium-font-size);
}

.FormContainer {
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    font-size: var(--small-font-size);
    display: flex;
    flex-direction: column;
    gap: 1vmin;
}

.ChangePwdSectionTitle {
}


.SectionName {
    text-align: left;
    font-size: var(--medium-font-size);
    padding-top: 2vmin;
    padding-bottom: 2vmin;
}

.BlockedTagsContainer, .BlockedMemeDevsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.BlockedTagContainer {
    padding: 0.5em;
    margin-top: 1vmin;
    margin-right: 1vmin;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
    border: 2px solid darkgray;
    border-radius: 1vmin;
    cursor: pointer;
}

.BlockedMemeDevContainer {
    width: 22%;
    padding-top: 1vmin;
    padding-right: 1vmin;
    padding-bottom: 1vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.BlockedMemeDevNickname {
    width: 100%;
    font-size: var(--smaller-font-size);
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.AvatarContainer {
    position: relative;
}

.AvatarInput {
    width: 1px;
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.AvatarInputLabel {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.AvatarInputImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    cursor: pointer;
    transition: 0.4s ease;
}

.AvatarInputImg:hover {
    opacity: 1;
}

.Setting {
    display: flex;
    align-items: center;
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    font-size: var(--small-font-size);
    color: var(--std-text-color);
}

.SettingImgContainer {
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SettingImg {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.SettingElement {
    width: 100%;
    /*max-width: calc(100% - 1.5em - 4vmin);*/
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.SettingFirstRow {
    width: 100%;
    display: flex;
    align-items: center;
    text-align: left;
}

.SettingRowClickable {
    min-width: 50%;
    padding-left: 1vmin;
    padding-right: 1vmin;
    border-radius: 1vmin;
    cursor: pointer;
}

.SettingRowUnclickable {
    padding-left: 1vmin;
    padding-right: 1vmin;
}

.SettingRowClickable:hover {
    background-color: rgba(0,0,0,5%);
}

.SettingSecondRow {
    padding-left: 1vmin;
    padding-right: 1vmin;
    font-size: var(--smaller-font-size);
    text-align: left;
    width: 100%;
    display: flex;
}

.AmnestyImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25em;
    padding: 0.25em;
    cursor: pointer;
}

.AmnestyImgContainer:hover {
    background-color: rgba(0,0,0,5%);
}

.AmnestyImg {
    height: 1em;
    object-fit: contain;
}


.Selector {
    margin-left: 1vmin;
    margin-right: 1vmin;
    border: none;
    border-radius: 1vmin;
    font-size: var(--small-font-size);
    /*background-color: var(--container-color);*/
    color: var(--std-text-color);
    cursor: pointer;
}

.Selector:focus{
    outline: none;
}

.Separator {
    border-top: solid 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 25%);
}

.SettingsModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.SettingsModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SettingsModalContent {
    background-color: var(--container-color);
    padding-left: 2vmin;
    padding-right: 2vmin;
    color: var(--std-text-color);
    font-size: var(--medium-font-size);
    border-radius: 1.5vmin;
    width: 70%;
    max-width: 600px;
}

.SettingsTitle {
    padding-top: 2vmin;
    padding-bottom: 2vmin;
    text-align: left;
    color: var(--main-color);
}

.SettingsHeader {
    display: flex;
    align-items: center;
    padding-bottom: 2vmin;
    padding-top: 2vmin;
}

.button {
    border: none;
    border-radius: 1vmin;
    color: white;
    background-color: var(--main-color);
    font-size: var(--small-font-size);
    padding: 1vmin;
    cursor: pointer;
}
