.OAuthButton {
    font-size: var(--small-font-size);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.5s ease;
}

.OAuthLogoImg {
    height: 1.7em;
}

.OAuthAppleLogoImg {
    height: 2em;
    padding-bottom: 0.3em;
}

.OAuthImgContainer {
    padding: 0.25em;
    display: flex;
    align-items: center;
    border-radius: 0.25em;
}

.OAuthImgContainer:hover {
    background-color: rgba(0,0,0,5%);
    transition: background-color 0.1s ease;
}