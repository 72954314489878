.LoaderContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vmin;
}

.Dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--main-color);
    margin: 0 5px;
    animation: pulse 1.5s infinite ease-in-out alternate;
}

.Dot-1 {
    animation-delay: -0.6s;
}

.Dot-2 {
    animation-delay: -0.4s;
}

.Dot-3 {
    animation-delay: -0.2s;
}

.Dot-4 {
    animation-delay: 0s;
}

.Dot-5 {
    animation-delay: 0.2s;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.5);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
    }
}
