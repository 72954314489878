.PopUpInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    text-align: left;
    padding: 0;
    font-size: 0;
    border-radius: 0;
    font-weight: normal;
    color: var(--std-text-color);
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
    filter: brightness(0.985);
    transition: 0.07s;
    z-index: 2;

    width: max-content; /* Подстраивается под текст */
    max-width: 27em;    /* Ограничение максимальной ширины */
    white-space: normal; /* Позволяет перенос строк */
    overflow-wrap: break-word; /* Переносит длинные слова */
}

.PopUpInfo.active {
    padding: 0.5em;
    font-size: var(--smaller-font-size);
    /*font-size: calc(var(--smaller-font-size) * 0.8);*/
    border-radius: 0.5em;
}