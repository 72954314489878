.Container {
    position: relative;
    margin-right: var(--navbar-right-margin);
    width: var(--navbar-width);

    font-size: var(--small-font-size);
    font-weight: 500;
    transition: 0.5s ease;
}

/*.NavbarWrapper {*/
/*    width: var(--navbar-width);*/
/*    position: fixed;*/
/*    top: 3em;*/
/*    transition: 0.5s ease;*/
/*}*/

.Navbar {
    position: sticky;
    top: 3em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100vh - 3em);
    box-shadow: var(--container-shadow);
    background-color: var(--container-color);
    padding-left: 0.5em;
    padding-right: 0.5em;

    z-index: 1;
}

.Topbar {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    font-size: var(--small-font-size);
}

.BottomBar {
    margin-top: 1vmin;
    margin-bottom: 1vmin;
    font-size: var(--smaller-font-size);
    bottom: 0;
}

.Row {
    position: relative;
    padding: 0.6em 0 0.6em 0.6em;
    border-radius: 1vmin;
}

.Row.active {
    cursor: pointer;
}

@keyframes pulse {
    0% {
        background-color: transparent;
    }
    10% {
        background-color: transparent;
    }
    50% {
        background-color: rgb(227, 229, 230);
    }
    80% {
        background-color: transparent;
    }
    100% {
        background-color: transparent;
    }
}

.Row.Pulsing {
    animation: pulse 1.7s infinite;
    transition: background-color 0.5s;
}

.Row.active:hover {
    background-color: rgba(0,0,0,5%);
    /*filter: brightness(90%);*/
}

.core {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.75em;
    white-space: break-spaces;
}

.CoreName {
    transition: 0.3s ease;
    word-break: break-word;
}

.StoreAndMediaNarrowBarContainer {
    display: flex;
    flex-direction: column;
}

.StoreAndMediaWideBarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: space-between;*/
    gap: 0.2em;
}

.StoreAndMediaFirstRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6em;
    overflow: hidden;
}

.StoreAndMediaSecondRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.6em;
    overflow: hidden;
}

.StoreAndMediaImg {
    height: 100%;
    object-fit: contain;
}

.MobileStoreLink {
    height: 1.2em;
    padding: 0.6em;
    border-radius: 1vmin;
    cursor: pointer;
}

.SocialMediaPopupContainer {
    display: flex;
    gap: 0.25em;
}

.SocialMediaLink {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.6em;
    height: 1.2em;
    border-radius: 1vmin;
    cursor: pointer;
}

.MobileStoreLink:hover,.SocialMediaLink:hover,.SocialMediaPopupBtn:hover {
    background-color: rgba(0,0,0,5%);
}

.SocialMediaPopupBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1em;
    padding: 0.6em;
    border-radius: 1vmin;
    cursor: pointer;
}

.SupportLink {
    font-size: 0.9em;
    color: var(--std-text-color);
    font-weight: normal;
    white-space: nowrap;
}

.SupportEmail {
    display: flex;
    align-items: center;
    overflow-x: hidden;
}

.SupportEmailRef {
    transition: 0.5s ease;
    text-decoration: none;
    font-weight: normal;
    white-space: nowrap;
}

.PopUpContainer {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
    border-radius: 1vmin;
    padding: 0.25em;
}

.Selected {
    color: var(--main-color);
    text-decoration: none;
    cursor: pointer;
}

.Pending {
    color: var(--std-text-color);
    text-decoration: none;
}

.LocaleSelectorTitle {
    padding: 0.5em 0 0.5em 0.5em;
    width: 10em;
    transition: 0.5s ease;
    font-size: var(--smaller-font-size);
    text-align: left;
}

.LocaleSelectorContainer {
    display: flex;
    align-items: center;
    padding-left: 1vmin;
}

.LocaleSelectorImg {
    width: 1em;
    height: 1em;
    object-fit: contain;
}

.UILangContainer {
    min-width: 10em;
    margin-right: 0.5em;
    padding: 0.6em 0 0.6em 0.6em;
    gap: 0.5em;

    display: flex;
    justify-content: left;
    align-items: center;
}

.UILangImg {
    width: 1em;
    height: 1em;
    object-fit: contain;
}

.UILangLabel {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
}

.UILangSelector {
    width: 100%;
    border: none;
    border-radius: 1vmin;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
    background-color: transparent;
}

.UILangSelector:focus{
    outline: none;
}

.UILangOption {
    background-color: var(--container-color);
}

