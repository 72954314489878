.TopBar {
    font-size: var(--medium-font-size);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1vmin;
}

.MemePanelBtnContainer {
    height: auto;
    width: auto;
}

.FileInputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6em;
}

.FileInput {
    width: 1px;
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.FileInputLabel {
    font-size: var(--smaller-font-size);
    font-weight: 500;
    word-break: break-word;
    color: var(--std-text-color);
    background-color: var(--container-color);

    cursor: pointer;
    border: 2px dashed #d8dce0;
    border-radius: 0.5vmin;

    width: 100%;
    padding: 0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vmin;
}

.FileInputLabel:hover {
    filter: brightness(95%);
}

.FileInputPicture {
    width: 1.5em;
    height: 1.5em;
    object-fit: contain;
}

.CurrentLanguage {
    font-size: var(--small-font-size);
    color: var(--std-text-color);
}

.MemeContainer {
    position: relative;
}

.SelectImgAttention {
    margin-top: 1vmin;
    /*border: 2px solid #d8dce0;*/
    border-radius: 1vmin;
    color: var(--std-text-color);
    font-size: var(--small-font-size);
    padding: 2vmin;
}

.Input {
    border: none;
    border-radius: 1vmin;
    font-size: var(--smaller-font-size);
    padding: 1vmin;
    background-color: white;
    color: var(--std-text-color);
}

.Input:focus{
    outline: none;
    background-color: white;
}

.Button {
    width: 100%;
    margin-top: 2vmin;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 1vmin;
}

.Button.AppendTitle {
    height: 2em;
    font-size: var(--smaller-font-size);

    color: var(--std-text-color);
    background-color: white;
    border: 1px solid rgba(0,0,0,7%);
    cursor: pointer;
}

.Button.UploadMeme {
    height: 3em;
    color: white;
    background-color: var(--main-color);
    border: 1px solid rgba(255,255,255,25%);
    box-shadow: 0 1px 2px rgba(0,0,0,15%);
    cursor: pointer;

    transition: 0.3s ease;
}

.Button.AppendTitle:hover {
    filter: brightness(95%);
}

.Button.UploadMeme:hover {
    filter: brightness(95%);
}

.Button.UploadMemeUnavailable {
    height: 3em;
    color: white;
    background-color: lightgray;
    border: 1px solid rgba(0,0,0,3%);
    cursor: default;
}

.AppendTitleImg {
    height: 1em;
    width: 1em;
    object-fit: contain;
    margin-right: 1vmin;
}

.CheckBoxContainer {
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
    gap: 0.5em;
    margin-top: 2vmin;
    margin-left: 1vmin;
    display: flex;
    align-items: center;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: white;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.05em;
    height: 1.05em;
    border: 2px solid currentColor;
    border-radius: 0.15em;
    transform: translateY(0.05em);

    /* for checked content */
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 80%;
    height: 80%;
    transform: scale(0);
    transition: 0.05s transform ease-in-out;
    background-color: var(--main-color);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.EnterTagsAttention {
    text-align: left;
    padding-left: 1vmin;
    padding-top: 2vmin;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
}

.TagsInputWrapper {
    width: 100%;
    margin-top: 1vmin;
    overflow: hidden;
    transition: 0.3s ease;
}

.TagsInput {
    resize: none;
    height: 2em;
    padding: 0.5em;
    width: calc(100% - 1em);
    font-size: var(--smaller-font-size);

    border: none;
    border-radius: 1vmin;
    background-color: white;
    color: var(--std-text-color);

    transition: 0.3s ease;
}

.TagsInput:focus{
    outline: none;
    background-color: white;
}

.FormIconListContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
}

.FormIconListTile {
    text-align: left;
    color: var(--std-text-color);
    font-size: var(--smaller-font-size);
    padding: 0.25em;
    white-space: break-spaces;
}

.FormIconContainer {
    border-radius: 0.4em;
    height: calc(var(--small-font-size)*2);
    width: calc(var(--small-font-size)*2);
    margin: 0.25em;
    cursor: pointer;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 25%);
    display: flex;
    justify-content: center;
    align-items: center;
}