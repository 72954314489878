.Container {
    position: relative;
    margin-top: 1vmin;
    padding-top: 1vmin;
    padding-bottom: 1vmin;
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
    font-size: var(--medium-font-size);
    color: var(--std-text-color);
}

.AdditionallyBtnContainer {
    position: absolute;
    top: 2.5vmin;
    right: 2vmin;
}

.Greetings {
    margin-bottom: 2vmin;
    font-weight: 500;
    font-size: var(--medium-font-size);
}

.SettingsBtn {
    position: absolute;
    top: 2vmin;
    right: 2vmin;
    height: 3vmin;
    width: 3vmin;
    cursor: pointer;
}

.MainInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 2vmin;
}

.AvatarContainerWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
}

.AvatarContainer {
    position: relative;
}

.AvatarInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0;
    cursor: pointer;
    overflow: hidden;
    z-index: 2;
}

.AvatarInputLabel {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.AvatarInputImg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s ease;
}

/*.AvatarInputImg:hover {*/
/*    opacity: 1;*/
/*}*/

.NickNameContainer {
    padding-bottom: 1vmin;
    word-break: break-word;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.35em;
    max-width: 85%;
}

.LevelContainer {
    display: flex;
    align-items: center;
    gap: 0.35em;
    max-width: 85%;
}

.PremiumStarImgContainer,.LevelInfoImgContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.PremiumStarImg {
    height: 1em;
    margin-bottom: 0.1em;
    object-fit: contain;
    cursor: pointer;
}

.LevelInfoImg {
    height: 1em;
    margin-top: 0.15em;
    object-fit: contain;
    cursor: pointer;
}

.NameContainer {
    padding-bottom: 1vmin;
    font-weight: 500;
}

.RatingInfoContainer {
    display: flex;
    flex-direction: column;
    min-width: 65%;
    padding-top: 2vmin;
    padding-bottom: 2vmin;
}

.RatingInfoRow {
    display: flex;
    flex-direction: row;
    padding-top: 1vmin;
    padding-bottom: 1vmin;
}

.RatingInfoElem {
    flex: 1;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.RatingInfoElemSeparator {
    border-left: solid 1px rgba(0, 0, 0, 0.15);
    margin-top: 0.6em;
    margin-bottom: 0.5em;
}

.RatingInfoName {
    font-size: 0.8em;
}

.RatingInfoValue {
    padding-bottom: 0.1em;
    font-weight: 500;
}

.Container.ContentSwitcher {
    display: flex;
    flex-direction: row;
}

.ContentSwitcherBtn {
    flex: 1;
    margin-left: 1px;
    margin-right: 1px;
    border: none;
    border-radius: 1vmin;
    padding: 1vmin;
    cursor: pointer;
    font-family: inherit;
    font-size: 0.8em;
}

.MemeListHeader {
    padding: 2vmin;
    border-radius: 1.5vmin;
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
}

.SortTypeContainer {
    display: flex;
    justify-content: space-between;
}

.SortTypeColumn {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1vmin;
}

.SortType {
    display: flex;
    align-items: center;
}

.SortTypeRadioBtn {
    border: 0px;
    height: 1.5em;
    width: 1.5em;
}

.SortTypeName {
    color: var(--std-text-color);
    font-size: var(--small-font-size);
    margin-left: 0.5vmin;
    padding-right: 1vmin;
    text-align: left;
    word-break: break-word;
}

.PanelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 1em;
    color: var(--std-text-color);
    font-size: var(--medium-font-size);
}

.PanelBottomLeft {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.PanelBlock {
    display: flex;
    align-items: center;
    gap: 0.25em;
}

.PanelBottomRight {
    text-align: right;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 0.25em;
}

.PanelImg {
    height: 1em;
    width: 1em;
}

.PanelValue {
}

.Container.Meme {
    padding: 0.5em 0.5em 0 0.5em;
    background-color: var(--container-color);
}

.BadgeListContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-radius: 1.5vmin;
    margin-top: 1vmin;
    margin-bottom: 1vmin;
    box-shadow: var(--container-shadow);
    font-size: var(--medium-font-size);
    color: var(--std-text-color);
    background-color: var(--container-color);
}

.BadgeList {
    position: relative;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;

    width: 86%;
    min-height: 10vmin;
}

.Scroll {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 7%;
}

.Scroll:hover {
    filter: brightness(95%);
}

.ScrollLeftImg {
    transform: rotate(90deg);
    width: 100%;
}

.ScrollRightImg {
    transform: rotate(-90deg);
    width: 100%;
}

.BadgeContainer {
    flex: 1 0 33.3%;
    max-width: 33.3%;
    margin-top: 2vmin;
    margin-bottom: 1vmin;
    font-size: 0.8em;
    color: var(--std-text-color);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BadgeImgContainerWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.BadgeImgContainer {
    position: relative;
    width: 50%;
}

.BadgeNameContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 90%;
}

.BadgeName {
    hyphens: manual;
    word-break: break-word;
}

.BadgeCountMark {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.5em;
    height: 1.5em;
    font-size: var(--smaller-font-size);
    border-radius: 50%;
    border: solid 1px rgba(255, 255, 255, 100%);

    background-color: var(--main-color);
    color: white;
}

.BadgeModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.BadgeModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.BadgeModalContent {
    background-color: var(--container-color);
    padding: 4vmin 2vmin 4vmin 2vmin;
    color: var(--std-text-color);
    font-size: var(--medium-font-size);
    border-radius: 1.5vmin;
    width: 30%;
    max-width: 600px;
}

.BadgeModalText {
    margin-top: 1vmin;
}

.BadgeModalTextSmall {
    margin-top: 1vmin;
    font-size: var(--small-font-size);
}

/*.Container.BadgeList::-webkit-scrollbar-track {*/
/*    border-radius: 0 0 11px 11px;*/
/*    background-color: var(--container-color);*/
/*    box-shadow: var(--container-shadow);*/
/*}*/

/*.BadgeList::-webkit-scrollbar {*/
/*    width: 0;*/
/*}*/