:root {
  /*bright background*/
  /*--main-color: rgb(24, 118, 210); !* text, buttons, header *!*/
  /*--bg-color: rgba(253, 253, 255, 0.96);*/
  /*--container-color:  #f5f7f9;*/
  /*--std-text-color: rgba(97, 97, 97, 255);*/
  /*--container-shadow: 0 0.3vmin 0.5vmin rgba(0, 0, 0, 0.2);*/
  /*--large-font-size: calc(15px + 2vmin);*/
  /*--medium-font-size: calc(12px + 1.5vmin);*/
  /*--small-font-size: calc(10px + 1.0vmin);*/
  /*--meme-img-border-radius: none;*/
  /*--meme-img-shadow: none;*/

  /*transparent containers bright*/
  /*--main-color: rgb(24, 118, 210);*/
  /*--bg-color: rgba(253, 253, 255, 0.96);*/
  /*--container-color:  rgba(253, 253, 255, 0.96);*/
  /*--std-text-color: rgba(97, 97, 97, 255);*/
  /*--container-shadow: none;*/
  /*--meme-img-border-radius: 1.5vmin;*/
  /*--meme-img-shadow: 0 2px 3px rgba(0, 0, 0, 20%);*/
  /*--large-font-size: calc(15px + 2vmin);*/
  /*--medium-font-size: calc(12px + 1.5vmin);*/
  /*--small-font-size: calc(10px + 1.2vmin);*/
  /*for this variant in battle field expand only image - more place for expanded image*/

  /*dark background*/
  --main-color: rgb(24, 118, 210);
  --bg-color: rgba(235, 238, 241, 0.96);
  --container-color: #f5f7f8;
  --std-text-color: rgba(97, 97, 97, 255);
  --red-color: rgba(255,55,0,0.7);
  --green-color: rgba(43, 194, 2, 0.87);

  --container-shadow: inset 0 0 2px rgba(0, 0, 0, 0.25);
  --btn-outer-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);

  --large-font-size: 30px;
  --medium-font-size: 25px;
  --small-font-size: 20px;
  --smaller-font-size: 17px;

  --navbar-width: 11em;
  --navbar-right-margin: 1vmin;
  --content-width: calc(100% - var(--navbar-width) - var(--navbar-right-margin));
  --layout-max-width: 800px;
  --layout-wide-max-width: 1700px;
  --navbar-narrow-width: calc(1em + 4vmin);
  --content-for-narrow-navbar-width: calc(100% - var(--navbar-narrow-width) - var(--navbar-right-margin));
  --scroll-width: 22px;

  /*--large-font-size: calc(13px + 1.8vmin);*/
  /*--medium-font-size: calc(10px + 1.5vmin);*/
  /*--small-font-size: calc(10px + 1.0vmin);*/
  /*--smaller-font-size: calc(8px + 1vmin);*/

  --meme-img-border-radius: none;
  --meme-img-shadow: none;
  --meme-title-color: black;

  --toastify-toast-min-height: 150px;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: var(--small-font-size);
  overflow-x: clip; /*clip to not brake navbar: sticky*/
}

.background {
  background-color: var(--bg-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
}

.layout {
  color: var(--main-color);
  max-width: var(--layout-max-width);
  width: 100%;
  display: flex;
  flex-direction: row;
  transition: 0.5s ease;
}

.BannerContainer {
  position: fixed;
  bottom: 0;
  left: calc(50% + var(--navbar-width)/2 + 0.5vmin);
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;
  gap: 1vmin;

  width: calc(var(--layout-max-width) - 2vmin - var(--navbar-width));
  max-width: 100%;
  z-index: 2;
}

::-webkit-scrollbar {
  width: var(--scroll-width);
  height: var(--scroll-width);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #e2e6ea;
  border-radius: 100px;
  border: 3px solid transparent;
  background-clip: content-box;
}

@keyframes pulse {
  0% {
      background-color: transparent;
  }
  15% {
      background-color: transparent;
  }
  50% {
      background-color: rgb(235, 237, 238);
  }
  70% {
      background-color: transparent;
  }
  100% {
      background-color: transparent;
  }
}

.Pulsing {
  animation: pulse 1.7s 15;
  transition: background-color 0.5s;
}

.Tooltip::before {
  content: attr(data-tooltip);
  position: absolute;
  padding: 1vmin;

  top: calc(100% + 0.5vmin);
  left: 50%;
  transform: translate(-50%);
  white-space: nowrap;

  background-color: white;
  border-radius: 1vmin;
  font-size: 0;
  opacity: 0;
  transition: 0.3s ease;

  z-index: 11;
}

.Tooltip:hover::before {
  opacity: 1;
  font-size: 0.8em;
  box-shadow: 0 0 2px rgba(0, 0, 0, 10%);
}

/* .Tooltip.active:hover::before {
  opacity: 1;
  font-size: 0.8em;
  box-shadow: 0 0 2px rgba(0, 0, 0, 10%);
} */