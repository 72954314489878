.BattlePage {
    height: calc(100vh - 3em);
    /*padding-bottom: 1vmin;*/
    /*padding-top: 1vmin;*/
    display: flex;
    flex-direction: column;
    gap: 1vmin;
}

.Greetings {
    margin-top: 1vmin;
    border-radius: 1.5vmin;
    height: 3em;
    width: 100%;
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75em;
    /*transition: 0.3s ease;*/
}

.GreetingsText {
    padding-left: 0.5em;
    padding-right: 0.5em;
    font-size: var(--medium-font-size);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Greetings.NoMoreBattles {
    height: calc(100vh - 3em - 2vmin);
}

.SignUpInfo {
    height: calc(100% - 6px);
    margin-right: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
    /* max-width: 35%; */
    font-weight: 400;
    border-radius: 0.5em;
    background-color: white;
    /* box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.25); */
}

.SignUpInfoText {
    font-size: 0.9em;
    padding: 4px 2.25em 4px 8px;
    white-space: pre;
}

.SignUpInfoImg {
    width: 1.2em;
    object-fit: contain;
    border-radius: 50%;
    padding: 3px;
    cursor: pointer;
}

/*.ScreenModeContainer {*/
/*    position: absolute;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 1vmin;*/
/*    z-index: 1;*/
/*    transition: 0.5s ease;*/
/*}*/

.ModalMemeBG {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.ModalMemeBG.active {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
}

.ModalMemeContainer{
    width: 98vw;
    max-width: var(--layout-max-width);
    background-color: var(--container-color);
    margin-top: 1vmin;
    padding: 0.5em 0.5em 1.5em 0.5em;
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
}

.BattleContainer {
    /*position: relative;*/
    /*height: calc(100vh - 23vmin);*/
    height: calc(100% - 4vmin - 6em);
    transition: 0.5s ease;
    display: flex;
    gap: 1vmin;
    /*z-index: 10;*/
}

/*.FullScreenBackground {*/
/*    position: fixed;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    padding-top: 8vmin;*/
/*    background-color: rgba(50, 50, 50, 100%);*/
/*    transition: 0.5s ease;*/
/*}*/

.MemeContainer {
    /*position: absolute;*/

    width: calc(50% - 0.5vmin - 1em);
    padding: 0.5em 0.5em 0 0.5em;
    font-size: var(--small-font-size);

    display: flex;
    flex-direction: column;

    background-color: var(--container-color);
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
    transition: 0.5s ease;
    /*z-index: 1;*/
}

/*.MemeContainer.expanded {*/
/*    width: 80%;*/
/*    z-index: 100;*/
/*}*/

.MemeContentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(15, 15, 15);
    flex: 1;
    overflow: hidden;

    transition: 0.5s ease;
    will-change: height, width;
}

.MemeContentContainer1 {
    max-height: 100%;
    overflow: hidden;
}


.ButtonsBar {
    /*position: absolute;*/
    width: 100%;
    height: 3em;

    background-color: rgb(60, 115, 180);
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);

    display: flex;

    /*z-index: 2;*/
}

.Button {
    flex: 1;
    border: none;
    color: white;
    background-color: transparent;
    cursor: pointer;
    font-size: var(--medium-font-size);
    transition: font-size 0.3s ease;
}

.Button:hover {
    font-size: calc(1.05 * var(--medium-font-size));
}

.ButtonsSeparator {
    width: 1vmin;
    background-color: var(--bg-color);
    /*background-color: var(--container-color);*/
    /*box-shadow: var(--container-shadow);*/
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 25%)
}

.LeftBtnArrow {
    height: 100%;
    transform: rotate(90deg);
    margin-bottom: 0.1vmin;
    margin-right: 0.5vmin;
}

.ScreenModeSelector {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vmin;
    position: relative;
    box-shadow: var(--container-shadow);
    background-color: var(--container-color);
    font-size: var(--small-font-size);
}

.ScreenModeFullScreen {
    width: 3vmin;
    height: 3vmin;
    cursor: pointer;
    transition: 0.3s ease;
}

.ScreenModeFullScreen:hover {
    width: 3.5vmin;
    height: 3.5vmin;
}

.ScreenModeCollapse {
    width: 3.5vmin;
    height: 3.5vmin;
    cursor: pointer;
    transition: 0.3s ease;
}

.ScreenModeCollapse:hover {
    width: 3vmin;
    height: 3vmin;
}

.ScreenModeSelector::before {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--bg-color);
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-radius: 0 0 1.5vmin 1.5vmin;
    white-space: nowrap;
    color: var(--std-text-color);
    font-size: 0;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.ScreenModeSelector:hover::before {
    content: attr(data-tooltip);
    font-size: 0.8em;
    padding: 1vmin;
    opacity: 1;
}

.ButtonSeparator {
    margin-top: 1vmin;
    margin-bottom: 1vmin;
    border: solid 1px white;
}

.StatsContainer {
    background-color: var(--container-color);
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
    color: var(--std-text-color);

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    padding: 2vmin;
    width: calc(100% - 4vmin);
}