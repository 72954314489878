.form {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.form.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    border-radius: 1.5vmin;
    width: 80%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    font-size: var(--smaller-font-size);
    background-color: var(--container-color);
    color: var(--std-text-color);
}

.formContent {
    display: flex;
    flex-direction: column;
    gap: 2vmin;
    padding: 3vmin 8vmin 0 8vmin;
}

.topBar {
    color: var(--main-color);
    padding: 2vmin;
    font-size: calc(15px + 1vmin);
    font-weight: bold;
}

.OAuthContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: min(2em, 2.5vmin);
}

.errorBar {
    min-height: 4vmin;
    padding-left: 2vmin;
    padding-right: 2vmin;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3em;
    text-align: left;

    word-wrap: break-word;
    color: red;
}

.RestorePwd {
    padding: 0vmin 1vmin 2vmin 1vmin;
    display: flex;
    justify-content: center;
    white-space: pre;
}

.Link {
    color: royalblue;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.bottomBar {
    border-top: solid 2px rgba(0, 0, 0, 0.35);
    padding: 2vmin;
    font-size: calc(10px + 1vmin);

    display: flex;
    justify-content: center;
    align-items: center;
}

.InputContainer {
    position: relative;
    /*margin-bottom: 2vmin;*/
}

.input {
    width: calc(100% - 1.7em);
    padding: 0.85em;
    font-size: var(--smaller-font-size);

    border: none;
    border-radius: 1vmin;
    background-color: white;
    color: var(--std-text-color);
}

.input:focus{
    outline: none;
    background-color: white;
}

.input.WrongSubmitPassword {
    border: 2px solid crimson;
    box-shadow: inset 0 0 2px crimson;
}

.BirthDateInputContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vmin;
    margin-bottom: 2vmin;
}

.BirthDateInputContainer::before,.InputContainerWithTooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    padding: 1vmin;

    top: calc(100% + 0.5vmin);
    left: 50%;
    transform: translate(-50%);
    white-space: nowrap;

    background-color: white;
    border-radius: 1vmin;
    font-size: 0;
    opacity: 0;
    transition: 0.3s ease;

    z-index: 11;
}

.BirthDateInputContainer:hover::before,.InputContainerWithTooltip:hover::before {
    opacity: 1;
    font-size: 0.8em;
    box-shadow: 0 0 2px rgba(0, 0, 0, 10%);
}

.input.BirthDate {
    flex: 1;
    margin-bottom: 0;
}

.LocaleContainer {
    margin-bottom: 2vmin;
    display: flex;
    justify-content: space-between;
    gap: 1vmin;
}

.LocaleLabel {
    position: relative;
    flex: 1;
}

.PopUpAttentionContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    padding: 1vmin;
    width: 13em;

    text-align: left;
    font-size: var(--smaller-font-size);
    font-weight: normal;
    background-color: var(--container-color);
    border-radius: 1vmin;
    box-shadow: var(--container-shadow);
    color: var(--std-text-color);

    z-index: 110;
}

.LocaleSelector {
    width: 100%;
    padding: 1vmin;
    border: none;
    border-radius: 1vmin;
    background-color: white;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
}

.LocaleSelector:focus{
    outline: none;
}

.button {
    border: none;
    border-radius: 1vmin;
    color: white;
    background-color: var(--main-color);
    font-size: var(--small-font-size);
    padding: 0.65em;
    margin-bottom: 2vmin;
    cursor: pointer;
}

.ModalAgreementContainer {
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
}

.CheckBoxContainer {
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
    gap: 0.5em;
    margin-left: 1vmin;
    display: flex;
    align-items: center;
}

input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: white;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.05em;
    height: 1.05em;
    border: 2px solid currentColor;
    border-radius: 0.15em;
    transform: translateY(0.05em);

    /* for checked content */
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type="checkbox"]::before {
    content: "";
    width: 80%;
    height: 80%;
    transform: scale(0);
    transition: 0.05s transform ease-in-out;
    background-color: var(--main-color);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.CheckboxLabel {
    text-align: left;
    flex-shrink: 10;
}

.ShowPwdBtn {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--smaller-font-size);

    background-color: transparent;
    border: none;
}

.ShowPwdBtnImg {
    height: 1em;
    width: 1em;
    object-fit: contain;
    cursor: pointer;
}

.RequiredMark {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1em;

    color: red;
    white-space: pre;
}