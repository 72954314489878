.Modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}

.Modal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Container {
    padding: 4vmin;
    font-size: var(--small-font-size);
    font-weight: 500;
    color: var(--std-text-color);
    background-color: var(--container-color);
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
}