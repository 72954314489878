.ImageLoadAnimation {
    position: relative;
    background: linear-gradient(-45deg, #ddd 40%, #eee 50%, #ddd 60%);
    /*-webkit-animation: Shimmer 1.5s linear infinite;*/
    /*-moz-animation: Shimmer 1.5s linear infinite;*/
    animation: Shimmer 1.5s linear infinite;
    background-size: 400% 400%;
}

.ObservingElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: transparent;
}

/*@-webkit-keyframes Shimmer {*/
/*    0%{background-position:0% 50%}*/
/*    50%{background-position:100% 50%}*/
/*    100%{background-position:0% 50%}*/
/*}*/
/*@-moz-keyframes Shimmer {*/
/*    0%{background-position:0% 50%}*/
/*    50%{background-position:100% 50%}*/
/*    100%{background-position:0% 50%}*/
/*}*/
@keyframes Shimmer {
    0%{background-position:100% 100%}
    /*50%{background-position:100% 50%}*/
    100%{background-position:0% 0%}
}