.Frame {
    position: fixed;
    top: 0;
    left: 0;
    height: 3em;
    background-color: var(--main-color);
    font-size: var(--small-font-size);
    color: white;

    display: flex;
    justify-content: center;

    z-index: 2;
}

.FramePanelContainer {
    width: var(--layout-max-width);

    display: flex;
    justify-content: space-between;
}

.FramePanelLeft {
    text-align: left;
    /*max-width: calc(var(--layout-max-width)/2);*/
    /*width: 50%;*/
    display: flex;
    align-items: center;
}

.FramePanelRight {
    text-align: right;
    /*max-width: calc(var(--layout-max-width)/2);*/
    /*min-width: 50%;*/
    display: flex;
    align-items: center;
    flex-direction: row-reverse;


    position: relative;
}

.FrameBackground {
    height: 3em;
}

.LogoContainer {
    padding-left: 1em;
    padding-right: 1em;
    margin: 0.25em;
}

.LogoImgContainer {
    position: relative;
}

.LogoImg {
    height: 2.5em;
}

.LogoImgNewYearDecor {
    position: absolute;
    font-size: 1.5em;
    top: 50%;
    transform: translateY(-50%);
    text-shadow:
            1px 0 0 white,
            0 1px 0 white,
            -1px 0 0 white,
            0 -1px 0 white;
}

.LogoImgNewYearDecor.Left {
    left: -1.05em;
}
.LogoImgNewYearDecor.Right {
    right: -0.95em;
}

.BetaMark {
    position: absolute;
    right: 0.45em;
    bottom: 0.3em;
    transform: translateX(100%);
    font-size: 0.9em;
    font-style: italic;
}

.AccountDeletionBannerContainer {
    font-size: var(--smaller-font-size);
    height: 100%;
    max-width: 50%;
}

.AccountDeletionBanner {
    height: calc(100% - 0.5em);
    margin: 0.25em;
    padding-left: 0.25em;
    padding-right: 0.25em;
    display: flex;
    align-items: center;
    gap: 0.5em;

    border-radius: 0.25em;
    background-color: var(--red-color);
    color: white;
    cursor: pointer;
}

.AccountDeletionBannerImg {
    height: 1em;
    object-fit: contain;
}

.AccountDeletionBannerMsg {
    text-align: left;
}

.ProfileImgContainer {
    height: 2.5em;
    width: 2.5em;
    margin: 0.25em 0.5em 0.25em 0.5em;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background-color: white;
}

.ProfileBtnContainer {
    display: flex;
    align-items: center;
}


.ProfilePopUpBtn {
    padding-right: 1vmin;
    padding-left: 1vmin;
    display: flex;
    align-items: center;

    cursor: pointer;
    transition: 0.2s ease;
}

.ProfilePopUpBtn:hover {
    background-color: rgba(0,0,0,5%);
}

.ProfileMenu {
    position: absolute;
    top: 3em;
    right: 0;
    max-width: 15em;

    display: flex;
    flex-direction: column;
}

.ProfilePopUp {
    display: flex;
    flex-direction: column;
    padding: 1vmin;
    background-color: var(--container-color);
    border-radius: 0 0 1.5vmin 1.5vmin;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 20%);
    min-width: 10em;
}

.VerificationBanner {
    display: flex;
    flex-direction: column;
    width: 13em;
    max-width: 15em;
    margin-top: 1vmin;
    padding: 1vmin;
    background-color: darkred;
    border-radius: 1.5vmin;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 20%);
    opacity: 0.85;
}

.ProfilePopUpSelectBtn {
    padding: 1vmin;
    border-radius: 1vmin;
    text-align: left;
    cursor: pointer;
    display: flex;
    color: var(--std-text-color);
    align-items: center;
    transition: 0.2s ease;
}
.ProfilePopUpSelectBtn:hover {
    background-color: rgba(0,0,0,5%);
}

.ProfilePopUpSelectBtnImgContainer {
    width: 1.1em;
    height: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProfilePopUpSelectBtnImg {
    object-fit: contain;
    max-height: 100%;
}