.Container {
    position: relative;
    padding: 1vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vmin;

    flex-wrap: nowrap;
    overflow: hidden;

    color: white;
    /*color: var(--std-text-color);*/
    /*color: var(--main-color);*/
    /*background-color: var(--container-color);*/
    /*background-color: var(--main-color);*/
    background-color: rgba(118, 147, 175, 0.98);
    /*box-shadow: var(--container-shadow);*/
    /*box-shadow: inset 0px 0px 2px var(--main-color);*/
    /*border-top: solid 1px var(--main-color);*/
    /*border-left: solid 1px var(--main-color);*/
    /*border-right: solid 1px var(--main-color);*/
    border-top: solid 1px rgba(255,255,255,20%);
    border-left: solid 1px rgba(255,255,255,20%);
    border-right: solid 1px rgba(255,255,255,20%);
    border-radius: 1vmin;
    transition: 0.5s ease;
}

.PromoBannerContainer {
    border-radius: 1vmin;
    border: 2px solid var(--main-color);
    background-color: rgb(240, 242, 243);
    box-shadow: var(--container-shadow);
    transition: 0.5s ease;
}

.hidden {
    /*height: 0;*/
    /*padding: 0;*/
    opacity: 0;
    z-index: -1;
}

.MobileMarketTitle {
    font-weight: 500;
    max-width: 75%;
}

.MobileMarketLinkListContainer {
    display: flex;
    gap: 0.5%
}

.MobileMarketLink {
    width: 33%;
}

.MobileMarketLinkImg {
    /*height: 2.5em;*/
    width: 100%;
    object-fit: contain;
}

.CookieInfoTitle {
    font-size: var(--small-font-size);
    font-weight: 500;
}

.CookieImgContainer {
    font-size: var(--smaller-font-size);
}

.CookieImg {
    font-size: var(--smaller-font-size);
    height: 10em;
    filter: drop-shadow(0 1px 5px white);
    -webkit-filter: drop-shadow(0 2px 6px white);
}

.CookieInfo {
    font-size: var(--smaller-font-size);
}

.UnderstoodBtn {
    position: absolute;
    right: 0.7em;
    top: 0.7em;
    padding: 0.35em;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0.25em;
    /*min-width: 8em;*/
    font-size: var(--small-font-size);
    /*background-color: var(--main-color);*/
    /*background-color: var(--std-text-color);*/
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
    /*box-shadow: 0 0px 2px rgba(0,0,0,50%);*/
    /*color: white;*/
    color: var(--std-text-color);
    font-weight: 500;
    cursor: pointer;
    opacity: 100%;
}

.UnderstoodBtn:hover {
    filter: brightness(90%);
}

.CloseImg {
    height: 0.8em;
    object-fit: contain;
}