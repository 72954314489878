.Content {
    width: var(--content-width);
    font-size: var(--small-font-size);
    position: relative;
    transition: 0.5s ease;
}

.BetaInfo {
    position: absolute;
    right: -1vmin;
    top: 1vmin;
    transform: translateX(100%);

    font-size: 0.8em;
    text-align: left;
    max-width: 285px;

    border-radius: 1.5vmin;
    color: var(--std-text-color);
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
}

.BetaInfoContent {
    position: relative;
}

.BetaInfoContentTextContainer {
    padding: 1vmin;
    text-align: justify;
    white-space: pre-line;
}