/*@import url('https://fonts.googleapis.com/css?family=Lobster&subset=latin,latin-ext');*/
/*@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');*/

body {
  margin: 0;
  overflow-y: scroll;
  overscroll-behavior: contain;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'Roboto', 'Noto Sans SC', 'M Plus Rounded1c', 'Arial Black', 'Comic Sans MS', 'Impact', 'Times New Roman', 'Lobster',
    'Mangal', 'Nirmala UI', 'Hind Bold', 'Mangal Bold',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*ui fonts*/
/*zh*/
@font-face {
  font-family: 'Noto Sans SC';
  src: url('../assets/fonts/zh/NotoSansSC-Regular.ttf') format('truetype');
  src: url('../assets/fonts/zh/NotoSansSC-Bold.ttf') format('truetype');
}
/*ja*/
@font-face {
  font-family: 'M Plus Rounded1c';
  src: url('../assets/fonts/ja/MPLUSRounded1c-Regular.ttf') format('truetype');
  src: url('../assets/fonts/ja/MPLUSRounded1c-Bold.ttf') format('truetype');
}


/*meme fonts*/

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
  url('../assets/fonts/Roboto-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Arial Black';
  src: url('../assets/fonts/Arial-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Comic Sans MS';
  src: url('../assets/fonts/Comic-Sans-MS.ttf') format('truetype');
}
@font-face {
  font-family: 'Impact';
  src: url('../assets/fonts/Impact.ttf') format('truetype');
}
@font-face {
  font-family: 'Times New Roman';
  src: url('../assets/fonts/Times-New-Roman.ttf') format('truetype');
}
@font-face {
  font-family: 'Lobster';
  src: url('../assets/fonts/Lobster-Regular.woff2') format('woff2'),
       url('../assets/fonts/Lobster-Regular.woff') format('woff');
}

/*for hindi*/

@font-face {
  font-family: 'Hind Bold';
  src: url('../assets/fonts/hi/Hind-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Mangal';
  src: url('../assets/fonts/hi/Mangal.ttf') format('truetype');
}
@font-face {
  font-family: 'Mangal Bold';
  src: url('../assets/fonts/hi/Mangal-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Nirmala UI';
  src: url('../assets/fonts/hi/Nirmala-ui.ttf') format('truetype');
}

/*for kk*/

@font-face {
  font-family: 'Caveat';
  src: url('../assets/fonts/kk/Caveat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Marmelad';
  src: url('../assets/fonts/kk/Marmelad-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Oswald';
  src: url('../assets/fonts/kk/Oswald-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Pacifico';
  src: url('../assets/fonts/kk/Pacifico-Regular.ttf') format('truetype');
}

/*for zh*/

@font-face {
  font-family: 'Liu Jian Mao Cao';
  src: url('../assets/fonts/zh/LiuJianMaoCao-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Noto Sans SC';
  src: url('../assets/fonts/zh/NotoSansSC-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ZCOOL Kuai Le';
  src: url('../assets/fonts/zh/ZCOOLKuaiLe-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ZCOOL Xiao Wei';
  src: url('../assets/fonts/zh/ZCOOLXiaoWei-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Zhi Mang Xing';
  src: url('../assets/fonts/zh/ZhiMangXing-Regular.ttf') format('truetype');
}

/*for ja*/

@font-face {
  font-family: 'Dela Gothic One';
  src: url('../assets/fonts/ja/DelaGothicOne-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Dot Gothic16';
  src: url('../assets/fonts/ja/DotGothic16-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Mochiy Pop One';
  src: url('../assets/fonts/ja/MochiyPopOne-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'M Plus Rounded1c';
  src: url('../assets/fonts/ja/MPLUSRounded1c-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Noto Serif JP';
  src: url('../assets/fonts/ja/NotoSerifJP-Regular.ttf') format('truetype');
}