.Container {
    background-color: var(--container-color);
    margin-top: 1vmin;
    padding: 1em 1em 0.25em 1em;
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);

    text-align: justify;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
}

.Title {
    text-align: center;
    font-size: var(--medium-font-size);
    color: var(--main-color);
    font-weight: 500;
}