.ModalBG {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.ModalBG.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContentContainer {
    width: calc(var(--layout-max-width) + 50px);
    max-width: 95%;
    font-size: var(--medium-font-size);
    border-radius: 1vmin;
    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
}