.Container {
    background-color: var(--container-color);
    margin-top: 1vmin;
    padding-left: 1vmin;
    padding-right: 1vmin;
    border-radius: 1.5vmin;
    box-shadow: var(--container-shadow);
}

.Container.Greetings {
    font-weight: 500;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoginLink {
    padding: 2vmin;
    font-weight: 500;
    color: var(--main-color);
    text-decoration: underline;
    cursor: pointer;
}

.Container.Error {
    padding: 2vmin;
}

.ModalMemeError {
    display: flex;
    justify-content: center;
}

.GreetingsText {
    font-size: var(--medium-font-size);
}

.ModalMemeBG {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.ModalMemeBG.active {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
}

.ModalMemeContainer {
    max-width: var(--layout-max-width);
    margin-bottom: 1vmin;
}

.MemeContent {
    position: relative;
    /*border-radius: var(--meme-img-border-radius);*/
    /*box-shadow: var(--meme-img-shadow);*/
    overflow: hidden;
}

.PanelContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 0.75em;
    color: var(--std-text-color);
}

.MemeWithHeadlineTitleTopPadding {
    height: 0.25em;
}

.MemeTopPadding {
    height: 0.75em;
}

.HeadlineTitle {
    padding-bottom: 0.5em;
    font-size: var(--small-font-size);
    text-align: left;
    color: var(--std-text-color);
}

.BottomPanelContainer {
    display: flex;
    justify-content: space-between;
    gap: 0.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    color: var(--std-text-color);
}

.TagPanelContainer {
    display: flex;
    /*gap: 1vmin;*/
    flex-wrap: wrap;
    overflow: hidden;
    font-size: var(--small-font-size);
    transition: 0.3s ease;
}

.TagContainer {
    padding: 0.5em;
    margin-bottom: 1vmin;
    margin-right: 1vmin;
    color: var(--std-text-color);
    border: 2px solid darkgray;
    border-radius: 1vmin;
    cursor: pointer;
}

.MemePosition {
    font-size: var(--large-font-size);
    text-align: left;
    display: flex;
    align-items: center;
}

.HotMemeHotAndRatingContainer {
    display: flex;
    gap: 0.35em;
}

.MemeHot {
    font-size: var(--medium-font-size);
    display: flex;
    align-items: center;
    gap: 0.25em;
}

.MemeRating  {
    font-size: var(--medium-font-size);
    text-align: right;
    display: flex;
    align-items: center;
    gap: 0.25em;
    cursor: pointer;
}

.MemeRatingImgContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MemeRatingImg {
    height: 1em;
}

.HotGreyFilterImg {
    filter: grayscale(1) brightness(1.4);
}

.HotColoredImg {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.PanelBottomLeft {
    display: flex;
    max-width: 60%;
}

.PanelBottomRight  {
    text-align: right;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-right: 0.25em;
}

.UserInfo {
    text-align: left;
    display: flex;
    align-items: center;
    max-width: 100%;
}

.UserInfo:hover {
    filter: brightness(0.85);
}

.Nickname {
    margin-left: 1vmin;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/*.ImageLoadAnimation {*/
/*    background: linear-gradient(-45deg, #ddd 40%, #eee 50%, #ddd 60%);*/
/*    !*-webkit-animation: Shimmer 1.5s linear infinite;*!*/
/*    !*-moz-animation: Shimmer 1.5s linear infinite;*!*/
/*    animation: Shimmer 1.5s linear infinite;*/
/*    background-size: 400% 400%;*/
/*}*/

/*!*@-webkit-keyframes Shimmer {*!*/
/*!*    0%{background-position:0% 50%}*!*/
/*!*    50%{background-position:100% 50%}*!*/
/*!*    100%{background-position:0% 50%}*!*/
/*!*}*!*/
/*!*@-moz-keyframes Shimmer {*!*/
/*!*    0%{background-position:0% 50%}*!*/
/*!*    50%{background-position:100% 50%}*!*/
/*!*    100%{background-position:0% 50%}*!*/
/*!*}*!*/
/*@keyframes Shimmer {*/
/*    0%{background-position:100% 100%}*/
/*    !*50%{background-position:100% 50%}*!*/
/*    100%{background-position:0% 0%}*/
/*}*/