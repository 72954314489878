.memeDevsContainer {
    position: relative;
    margin-top: 1vmin;
    border-radius: 1.5vmin;
    border: solid 1px rgba(0, 0, 0, 4%);
    overflow: hidden;
    box-shadow: var(--container-shadow);
    font-size: var(--medium-font-size);
}

.greetings {
    margin-bottom: 1vmin;
    font-weight: 500;
    font-size: var(--medium-font-size);
}

.separator {
    border-top: solid 2px rgba(0, 0, 0, 0.2);
    margin: 0 2vmin 0 2vmin;
}

.memeDevContainer {
    padding: 2vmin 2vmin 2vmin 1vmin;
}
.memeDevContainer.first {
    /*background-color: #fff9eb;*/
    padding: 4vmin 2vmin 2vmin 1vmin;
    background: linear-gradient(to right, #faf1dc, #fff9eb 10%, white 40%, white 45%, #faf1dc 80%);
}
.memeDevContainer.second {
    /*background-color: #fcfcfc;*/
    background: linear-gradient(to right, #ebebeb, #f5f5f5 10%, white 35%, white 45%, #e6e6e6 80%);
}
.memeDevContainer.third {
    /*background-color: #fff6f0;*/
    background: linear-gradient(to right,  #fceadf, #fff6f0 10%, white 50%, #fceadf 90%);
}
.memeDevContainer.std.even {
    background-color: #edf5ff;
}
.memeDevContainer.std.odd {
    background-color: #f8fcff;
}
.memeDevContainer.ten.even {
    background-color: #edf5ff;
}
.memeDevContainer.ten.odd {
    background-color: #f8fcff;
}

.memeDevContainer:hover {
    filter: brightness(97%);
}

.memeDevInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--std-text-color);
    text-align: left;
    width: 100%;
    max-width: 400px;
}

.NickNameContainer {
    display: flex;
    align-items: center;
    gap: 0.35em;
}

.PremiumStarImg {
    height: 0.8em;
    margin-top: 0.05em;
    object-fit: contain;
}

.NameContainer {
    padding-bottom: 1vmin;
    font-weight: 500;
}

.ratingInfoContainer {
    display: flex;
    justify-content: space-between;
}

.ratingInfoSection {
    display: flex;
    align-items: center;
    font-size: 0.9em;
}

.ratingInfoImg {
    display: flex;
    height: 1em;
}

.ratingInfoVal {
    margin-left: 0.5em;
}

.position {
    white-space: nowrap;
}

.avatar {
}

.position.first {
    color: #ffbc19;
    font-weight: bold;
}
.avatar.first {
    border: 0.15em solid #ffbc19;
}

.position.second {
    color: darkgray;
    font-weight: bold;
}
.avatar.second {
    border: 0.15em solid darkgray;
}

.position.third {
    color: #cd7f32;
    font-weight: bold;
}
.avatar.third {
    border: 0.15em solid #cd7f32;
}

.position.ten {
    color: var(--main-color);
    font-weight: 500;
}
.avatar.ten {
    border: 0.1em solid var(--main-color);
}

.position.std {
    color: var(--std-text-color);
    font-weight: 500;
}