/*.BuilderContainer {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    width: 100%;*/
/*}*/

.ConstructorSelectorContainer {
    border-radius: 1.5vmin;
    height: 3em;
    margin-top: 1vmin;
    margin-bottom: 1vmin;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: var(--container-color);
    box-shadow: var(--container-shadow);
    color: var(--main-color);
    font-weight: 500;
    transition: 0.3s ease;
}

.SelectConstructorBtn {
    height: 2.5em;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.SelectConstructorBtn:hover {
    filter: brightness(90%);
}

.SelectConstructorBtn.Left {
    transform: rotate(90deg);
}

.SelectConstructorBtn.Right {
    transform: rotate(-90deg);
}

.SelectConstructorBtnImg {
    height: 100%;
    width: auto;
}

.SelectConstructorBtnText {
    font-size: var(--small-font-size);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Container {
    position: relative;
    border-radius: 1.5vmin;
    padding: 0.5em;
    margin-bottom: 1vmin;

    box-shadow: var(--container-shadow);
    background-color: var(--container-color);
}

.ContainerCover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(245, 247, 248, 0.55);
    border-radius: 1.5vmin;
    transition: 0.3s ease;
}

.TemplateSelectorContainer {
    display: flex;
    gap: 1vmin;
    /*width: 100%;*/
}

.PreviewList {
    position: relative;
    border-radius: 1.5vmin;
    padding: 0.75em 0.25em 0em 0.25em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /*flex: 1;*/
    /*width: calc(100% - 8em - 1vmin);*/
}

.PreviewListScroll {
    /*height: 6em;*/
    /*min-height: 80px;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*overflow-x: scroll;*/
    /*overflow-y: hidden;*/
}

.TemplateTagList {
    height: 3em;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
    display: flex;
    flex-direction: column;
}

.TemplateTag {
    /*text-overflow: ellipsis;*/
    /*white-space: nowrap;*/
    /*overflow: hidden;*/
}

.PreviewListInfo {
    display: flex;
    align-items: center;
}

.TemplatePreviewContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.TagsFilterContainer {
    display: flex;
    gap: 0.5em;
    /*margin-top: 0.5em;*/
}

.TagsFilterInputContainer {
    flex: 1;
    height: 2em;
    display: flex;
    gap: 0.5em;
}

.SearchBtn {
    width: 2em;
    height: 2em;
    font-size: var(--small-font-size);
    background-color: white;
    box-shadow: var(--container-shadow);
    border-radius: 0.5em;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SearchBtn:hover {
    filter: brightness(95%);
}

.SearchImg {
    height: 60%;
}

.ExpandPreviewListBtn {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: var(--small-font-size);
    width: 2em;
    height: 2em;
    border-radius: 0.5em;

    text-decoration: underline;
    cursor: pointer;
    border: none;
    background-color: white;
    /*border: 1px solid rgba(255, 255, 255, 25%);*/
    /*background-color: white;*/
    box-shadow: var(--container-shadow);
    /*box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.75);*/
    /*box-shadow: 0 1px 2px rgba(0,0,0,15%);*/
}

@keyframes pulse {
    0% {
        background-color: white;
    }
    15% {
        background-color: white;
    }
    50% {
        background-color: rgb(235, 237, 238);
    }
    70% {
        background-color: white;
    }
    100% {
        background-color: white;
    }
}

.ExpandPreviewListBtn.Pulsing {
    animation: pulse 1.7s 15;
    transition: background-color 0.5s;
}

.ExpandPreviewListBtn:hover {
    filter: brightness(95%);
}

.ExpandPreviewListBtnImg {
    height: 1.2em;
}

.TagsFilterInput {
    flex: 1;
    padding: 0.5em;

    border: none;
    border-radius: 0.5em;
    font-size: var(--smaller-font-size);

    background-color: white;
    color: var(--std-text-color);
}

.TagsFilterInput:focus{
    outline: none;
    background-color: white;
}

.FormIconListContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: var(--smaller-font-size);
    color: var(--std-text-color);
}

.FormIconListTitle {
    text-align: left;
    color: var(--std-text-color);
    font-size: var(--smaller-font-size);
    padding: 0.25em;
    white-space: break-spaces;
}

.FormIconContainer {
    border-radius: 0.4em;
    height: calc(var(--small-font-size)*2);
    width: calc(var(--small-font-size)*2);
    margin: 0.25em;
    cursor: pointer;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 25%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalBG {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.ModalBG.active {
    display: flex;
    justify-content: center;
    align-items: center;
}