.CountdownContainer {
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    font-size: var(--large-font-size);
}

.Greetings {
    color: var(--main-color);
    font-weight: 500;
    font-size: 1.5em;
    text-align: center;
}

.Countdown {
    color: var(--std-text-color);
    font-weight: 500;
    text-align: center;
}

.Timer {
    color: var(--std-text-color);
    text-align: center;
}

.LetsWait {
    color: var(--std-text-color);
    text-align: right;
}

.Confetti {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    pointer-events: none;
    animation: confetti-fall 2s linear 1;
}

@keyframes confetti-fall {
    0% { top: -20%; opacity: 1; }
    100% { top: 100%; opacity: 0; }
}